import React, { useCallback, useEffect, useState } from 'react'
import { useApi } from 'api/ApiContext'
import { ForumCategory, ForumPost } from 'types/forum'

interface UseForumPostData {
  categories: ForumCategory[]
  post: ForumPost | null
  comments: ForumPost[]
  setComments: React.Dispatch<React.SetStateAction<ForumPost[]>>
  isLoading: boolean
  error: Error | null
}

export function useForumPostData(postId: string): UseForumPostData {
  const api = useApi()
  const [categories, setCategories] = useState<ForumCategory[]>([])
  const [post, setPost] = useState<ForumPost | null>(null)
  const [comments, setComments] = useState<ForumPost[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const homeRes = await api.forum.getHome()
      const homeData = homeRes.data.data.attributes
      setCategories(homeData.categories?.data || [])
      const postRes = await api.forum.getPost(postId)
      const commentsData = postRes.data.comments?.data || []
      const postData = postRes.data.post.data
      setPost(postData)
      setComments(commentsData)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [api.forum, postId])

  useEffect(() => {
    fetchData().catch(console.error)
  }, [fetchData])

  return { categories, post, comments, setComments, isLoading, error }
}
