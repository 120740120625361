import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApi } from 'api/ApiContext';
import '../../../grants/pages/GrantsList/pagination.css';

import { CreateEventData } from '../../../../api/EventsClient';

import styles from './NewEventPage.module.scss';
import { messages } from './messages';
import { NewEventForm } from './components/NewEventForm';

interface CountryItem {
  attributes: {
    name: string;
    id: string;
  };
}

interface CountriesApiResponse {
  data: CountryItem[];
}

interface CountryOption {
  label: string;
  value: string;
  disabled: boolean;
}

export const NewEventPage: React.FC = () => {
  const api = useApi();
  const { formatMessage } = useIntl();

  const [countries, setCountries] = useState<CountryOption[]>([]);
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await api.events.getCountries();
        const parsedCountries = (response.data as CountriesApiResponse).data.map(item => ({
          label: item.attributes.name,
          value: item.attributes.id,
          disabled: false,
        }));
        setCountries(parsedCountries);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCountries().catch(console.error);
  }, [api]);

  const saveEvent = async (values: CreateEventData) => {
    try {
      if (!values) return;

      const response = await api.events.createEvent(values);
      if (response.status === 201) {
        setShowForm(false);
      }
    } catch (error) {
      if (error.response?.status === 422) {
        console.error('Validation error:', error.response.data);
      } else {
        console.error('Error while saving event:', error);
      }
    }
  };

  return (
    <section className={styles.wrapper}>
      <Helmet title={formatMessage(messages.pageName)} />
      <section className={styles.headingContainer}>
        <div className={styles.headingContentWrapper}>
          <h1 className={styles.headingTitle}>
            <span>
              <FormattedMessage {...messages.pageTitle} />
            </span>
          </h1>

          <h5 className={styles.headingSubtitle}>
            <FormattedMessage {...messages.headingSubtitle} />
          </h5>

          <p className={styles.headingDescription}>
            <FormattedMessage {...messages.headingDescription} />
          </p>
        </div>
      </section>

      <section className={styles.newEvent}>
        {showForm ? (
          <NewEventForm countryOptions={countries} onSubmit={saveEvent} />
        ) : (
          <h2 style={{ padding: '2rem' }}>
            <FormattedMessage {...messages.eventSubmissionSuccess} />
          </h2>
        )}
      </section>
    </section>
  );
};
