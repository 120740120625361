import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ForumPost } from 'types/forum';
import { ReactComponent as UpvoteIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as DownvoteIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { useClickOutside } from '../../hooks/useClickOutside';
import { ReportModal } from '../ReportModal/ReportModal';
import defaultAvatar from 'assets/icons/default-avatar.svg';
import { useApi } from 'api/ApiContext';
import { messages } from './messages';
import styles from './PostCard.module.scss';

interface PostCardProps {
  post: ForumPost;
  handleVote?: (postId: string, voteType: 'upvote' | 'downvote') => Promise<void>;
}

export const PostCard: React.FC<PostCardProps> = ({ post, handleVote }) => {
  const api = useApi();
  const {
    id,
    attributes: {
      content,
      timeAgo,
      repliesCount,
      category,
      postedBy,
      relationUser,
      relationReactions,
    },
  } = post;

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, () => setIsMenuOpen(false));

  const avatarUrl = relationUser?.data?.attributes?.avatarUrl || defaultAvatar;

  const handleMenuClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleReportClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMenuOpen(false);
    setIsReportModalOpen(true);
  };

  const handleReportSubmit = async (values: { reason: string }) => {
    try {
      await api.forum.createReport(String(id), values.reason);
      setIsReportModalOpen(false);
    } catch (error) {
      console.error('Error submitting report:', error);
    }
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.categoryLabel}>
            c/{category || 'unknown_category'}
          </div>
          <div className={styles.menuContainer} ref={menuRef}>
            <button onClick={handleMenuClick} className={styles.menuButton}>
              <MoreIcon className={styles.menuIcon} />
            </button>
            {isMenuOpen && (
              <div className={styles.menu}>
                <button className={styles.menuItem} onClick={handleReportClick}>
                  <FormattedMessage {...messages.report} />
                </button>
              </div>
            )}
          </div>
        </div>

        <Link to={ROUTES.FORUM_POST.replace(':id', String(id))} className={styles.postLink}>
          <div className={styles.authorInfo}>
            <img src={avatarUrl} alt="User avatar" className={styles.avatar} />
            <div className={styles.authorMetadata}>
              <span className={styles.authorName}>{postedBy || 'anonymous'}</span>
              <span className={styles.timeAgo}>
                {timeAgo && <FormattedMessage {...messages.timeAgo} values={{ time: timeAgo }} />}
              </span>
            </div>
          </div>
          <div className={styles.content}>{content}</div>
        </Link>

        <div className={styles.footer}>
          <div className={styles.voteGroup}>
            <button
              className={styles.voteButton}
              aria-label="Upvote"
              onClick={() => handleVote?.(id, 'upvote')}
            >
              <UpvoteIcon className={`${styles.icon} ${styles.up}`} />
            </button>
            <span className={`${styles.voteCount} ${styles.up} ${styles.linkColor}`}>
              {relationReactions.upvote}
            </span>
            <span className={`${styles.voteCount} ${styles.down} ${styles.linkColor}`}>
              {relationReactions.downvote}
            </span>
            <button
              className={styles.voteButton}
              aria-label="Downvote"
              onClick={() => handleVote?.(id, 'downvote')}
            >
              <DownvoteIcon className={`${styles.icon} ${styles.down}`} />
            </button>
          </div>
          <Link to={ROUTES.FORUM_POST.replace(':id', String(id))} className={styles.commentButton}>
            <CommentIcon className={styles.icon} />
            {repliesCount}
          </Link>
        </div>
      </div>
      <ReportModal
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        onSubmit={handleReportSubmit}
        postId={id}
      />
    </>
  );
};
