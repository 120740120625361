import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ROUTES } from 'config/routes'
import {
  POLONIUM_TWITTER,
  POLONIUM_FACEBOOK,
  POLONIUM_YOUTUBE,
  POLONIUM_LINKEDIN,
} from 'config/constants'
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg'
import { ReactComponent as Youtube } from 'assets/icons/youtube.svg'
import { ReactComponent as LinkedIn } from 'assets/icons/linkedin.svg'
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg'
import PoloniumLogo from 'assets/icons/logo_dark.png'
import MfaLogo from 'assets/images/pl_ministerstwo_spraw_zagranicznych_logo.svg'
import { messages } from './messages'
import styles from './Footer.module.scss'

export const Footer = () => (
 <footer className={styles.footerWrapper}>
   <div className={styles.footerContainer}>
     <div className={styles.footerRow}>
       <div className={styles.footerColumn}>
         <h5 className={styles.footerTitle}>
           <FormattedMessage {...messages.foundationSectionTitle} />
         </h5>
         <div className={styles.textContainer}>
           <Link to={ROUTES.ABOUT} className={styles.linkItem}>
             <FormattedMessage {...messages.foundationAboutUsLink} />
           </Link>
           <Link to={ROUTES.PRIVACY} target="_blank" className={styles.linkItem}>
             <FormattedMessage {...messages.foundationPrivacyPolicyLink} />
           </Link>
           <Link to={ROUTES.TERMS_OF_SERVICE} target="_blank" className={styles.linkItem}>
             <FormattedMessage {...messages.foundationTermsLink} />
           </Link>
         </div>
       </div>
       <div className={styles.footerColumn}>
         <h5 className={styles.footerTitle}>
           <FormattedMessage {...messages.contactSectionTitle} />
         </h5>
         <p className={styles.textContainer}>
           <FormattedMessage {...messages.contactFoundationName} />
           <br />
           <FormattedMessage {...messages.contactAddress} />
           <br />
           <FormattedMessage {...messages.contactNip} />
         </p>
       </div>
       <div className={styles.footerColumn}>
         <div className={styles.socialIconsContainer}>
           <a
             href={POLONIUM_TWITTER}
             className={styles.socialIconItem}
             rel="noreferrer noopener"
             target="_blank"
           >
             <Twitter className={styles.socialIconSvg} />
           </a>
           <a
             href={POLONIUM_FACEBOOK}
             className={styles.socialIconItem}
             rel="noreferrer noopener"
             target="_blank"
           >
             <Facebook className={styles.socialIconSvg} />
           </a>
           <a
             href={POLONIUM_LINKEDIN}
             className={styles.socialIconItem}
             rel="noreferrer noopener"
             target="_blank"
           >
             <LinkedIn className={styles.socialIconSvg} />
           </a>
           <a
             href={POLONIUM_YOUTUBE}
             className={styles.socialIconItem}
             rel="noreferrer noopener"
             target="_blank"
           >
             <Youtube className={styles.socialIconSvg} />
           </a>
         </div>
       </div>
     </div>
     <div className={styles.grantsAndEventsContainer}>
       <div className={styles.grantsOrEventsColumn}>
         <FormattedMessage {...messages.disclaimerText} />
       </div>
       <div className={styles.grantsOrEventsColumn}>
         <h4>
           <FormattedMessage {...messages.grantsHeading} />
         </h4>
         <FormattedMessage {...messages.grantsDescription} />
       </div>
       <div className={styles.grantsOrEventsColumn}>
         <div className={styles.forumEventsRow}>
           <h4>
             <FormattedMessage {...messages.forumEventsHeading} />
           </h4>
           <img
             src={MfaLogo}
             alt="Ministry of Foreign Affairs"
             className={styles.mszForumLogo}
           />
           <FormattedMessage {...messages.forumEventsDescription} />
         </div>
       </div>
     </div>
     <div className={styles.footerBottom}>
       <img src={PoloniumLogo} className={styles.foundationLogo} alt="Foundation Logo" />
       <p>
         <FormattedMessage
           {...messages.copyrights}
           values={{
             yearsRange: `2019-${new Date().getFullYear()}`,
           }}
         />
       </p>
     </div>
   </div>
 </footer>
);
