import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'ForumHomePage.pageTitle',
    defaultMessage: 'Forum',
  },
  pageName: {
    id: 'ForumHomePage.pageName',
    defaultMessage: 'Forum',
    description: 'Forum page name',
  },
  forumTitle: {
    id: 'ForumHomePage.title',
    defaultMessage: 'Forum',
    description: 'Forum page title',
  },
  headingSubtitle: {
    id: 'ForumHomePage.headingSubtitle',
    defaultMessage: 'Connect, Collaborate, and Exchange Ideas',
    description: 'Forum page heading subtitle',
  },
  headingDescription: {
    id: 'ForumHomePage.headingDescription',
    defaultMessage:
      'Join vibrant discussions with researchers from around the world. Our forum is a space to share insights, seek advice, and spark collaborations. Whether you’re exploring new methods, seeking answers to challenges, or simply looking to connect with like-minded individuals, this is the place for you. Be part of the conversation and strengthen the global research community.',
    description: 'Forum page heading description',
  },
  categoryTitle: {
    id: 'ForumHomePage.categoryTitle',
    defaultMessage: 'Category',
    description: 'Category title',
  },
  categoryDescription: {
    id: 'ForumHomePage.categoryDescription',
    defaultMessage: 'Description',
    description: 'Category description',
  },
  latestPostsTitle: {
    id: 'ForumHomePage.latestPostsTitle',
    defaultMessage: 'Latest posts',
    description: 'Latest posts title',
  },
  errorLoading: {
    id: 'ForumHomePage.errorLoading',
    defaultMessage: 'Error loading forum: {error}',
  },
});
