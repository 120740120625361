import React, { useRef } from 'react';
import { Button } from 'components/Button/Button';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import styles from './ImageUpload.module.scss';
import { FormattedMessage } from 'react-intl';
import { messages } from './messages';

interface ImageUploadConfig {
  maxSizeInMB: number;
  maxSizeInBytes: number;
  acceptedFormats: string;
  acceptedFormatsList: string[];
}

interface ImageUploadError {
  message: string;
  code: 'size' | 'format';
}

interface ImageUploadProps {
  value: File | null;
  onChange: (file: File | null) => void;
  config: ImageUploadConfig;
  onError?: (error: ImageUploadError) => void;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
                                                          value,
                                                          onChange,
                                                          config,
                                                          onError,
                                                        }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFile = (file: File): ImageUploadError | null => {
    if (file.size > config.maxSizeInBytes) {
      return {
        code: 'size',
        message: `Plik jest za duży. Maksymalny rozmiar to ${config.maxSizeInMB}MB`,
      };
    }

    const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
    if (!config.acceptedFormatsList.includes(fileExtension)) {
      return {
        code: 'format',
        message: `Niedozwolony format pliku. Dozwolone formaty to: ${config.acceptedFormats}`,
      };
    }

    return null;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const error = validateFile(file);
      if (error) {
        onError?.(error);
        event.target.value = '';
        return;
      }
      onChange(file);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleContainerClick = () => {
    if (!value) {
      fileInputRef.current?.click();
    }
  };

  return (
    <div className={styles.imageUploadWrapper}>
      <div
        className={styles.imageContainer}
        onClick={handleContainerClick}
        aria-label="Click or drop file to upload image"
      >
        {value ? (
          <img
            src={URL.createObjectURL(value)}
            alt="Preview"
            className={styles.preview}
          />
        ) : (
          <div className={styles.placeholder}>
            <CameraIcon className={styles.cameraIcon} />
            <span>Drag & drop or click to upload</span>
          </div>
        )}
      </div>

      <div className={styles.buttonsContainer}>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className={styles.hiddenInput}
        />

        <Button
          type="button"
          className={styles.uploadButton}
          onClick={handleUploadClick}
        >
          <FormattedMessage {...messages.addPhoto} />
        </Button>
      </div>
    </div>
  );
};
