// ForumHomePage.tsx
import React, { useCallback, useEffect, useState } from 'react'
import { useApi } from 'api/ApiContext'
import { Helmet } from 'react-helmet-async'
import { ForumCategory, ForumPost } from 'types/forum'
import { FormattedMessage, useIntl } from 'react-intl'
import { Loading } from 'components/Loading/Loading'
import { useForumData } from '../hooks/useForumData'
import { CategoriesSidebar } from '../components/CategoriesSidebar/CategoriesSidebar'
import { PostList } from '../components/PostList/PostList'
import { NewPostForm } from '../components/NewPostForm/NewPostForm'
import { messages } from './messages'
import styles from './ForumHomePage.module.scss'

export function ForumHomePage() {
  const api = useApi()
  const { categories, isLoading: isLoadingInitial, error } = useForumData()
  const [posts, setPosts] = useState<ForumPost[]>([])
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null)
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const perPage = 20

  const fetchPosts = useCallback(
    async (categoryId: string | null, pageNr: number) => {
      setIsLoading(true)
      try {
        let response
        let data
        if (categoryId) {
          response = await api.forum.getCategoryPostsWithPagination(categoryId, {
            page: pageNr,
            per_page: perPage,
          })
          data = response.data.data
        } else {
          response = await api.forum.getHomeWithPagination({ page: pageNr, per_page: perPage })
          data = response.data.data.attributes.posts.data
        }
        if (data.length < perPage) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
        setPosts(prev => [...prev, ...data])
      } finally {
        setIsLoading(false)
      }
    },
    [api.forum]
  )

  useEffect(() => {
    setPosts([])
    setPage(1)
    setHasMore(true)
    if (!isLoadingInitial && categories) {
      fetchPosts(selectedCategoryId, 1).catch(console.error)
    }
  }, [selectedCategoryId, isLoadingInitial, categories, fetchPosts])

  useEffect(() => {
    const handleScroll = () => {
      if (isLoading || !hasMore) return
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500) {
        setPage(prev => prev + 1)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isLoading, hasMore])

  const handleVote = useCallback(
    async (postId: string, type: 'upvote' | 'downvote') => {
      try {
        api.forum.createReaction(postId, type).catch(console.error);
      } catch (error) {
        console.error('Error submitting vote:', error);
      }
    },
    [api.forum]
  );

  useEffect(() => {
    if (page > 1) {
      fetchPosts(selectedCategoryId, page).catch(console.error)
    }
  }, [page, selectedCategoryId, fetchPosts])

  const handleCreatePost = async ({
                                    title,
                                    content,
                                    categoryId,
                                  }: {
    title: string
    content: string
    categoryId: string
  }) => {
    try {
      const response = await api.forum.createPost({ categoryId, content })
      const newPost = response.data.data
      setPosts(prev => [newPost, ...prev])
    } catch {}
  }

  if (error) {
    return (
      <div>
        <FormattedMessage {...messages.errorLoading} values={{ error: error.message }} />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <Helmet title={formatMessage(messages.forumTitle)} />
      <section className={styles.headingContainer}>
        <div className={styles.headingContentWrapper}>
          <div>
            <h1 className={styles.headingTitle}>
              <span>
                <FormattedMessage {...messages.pageTitle} />
              </span>
            </h1>
            <h5 className={styles.headingSubtitle}>
              <FormattedMessage {...messages.headingSubtitle} />
            </h5>
          </div>
          <p className={styles.headingDescription}>
            <FormattedMessage {...messages.headingDescription} />
          </p>
        </div>
      </section>
      <div className={styles.content}>
        <Loading isLoading={isLoadingInitial}>
          <div className={styles.mainLayout}>
            <CategoriesSidebar
              categories={categories}
              onCategorySelect={catId => setSelectedCategoryId(catId)}
            />
            <main className={styles.mainContent}>
              <NewPostForm
                categories={categories as ForumCategory[]}
                selectedCategoryId={selectedCategoryId}
                onCreatePost={handleCreatePost}
              />
              <PostList posts={posts} handleVote={handleVote} />
              {isLoading && <p>Loading more posts...</p>}
              {!hasMore && <p>No more posts to load.</p>}
            </main>
          </div>
        </Loading>
      </div>
    </div>
  )
}
