import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from 'components/Modal/Modal';
import { TextAreaField } from 'components/TextAreaField/TextAreaField';
import { Button } from 'components/Button/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import styles from './ReportModal.module.scss';

interface ReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: { reason: string }) => void;
  postId: string | number;
}

const validationSchema = Yup.object().shape({
  reason: Yup.string()
    .min(10, 'Report reason must be at least 10 characters')
    .required('Please provide a reason for reporting'),
});

export const ReportModal: React.FC<ReportModalProps> = ({ isOpen, onClose, onSubmit, postId }) => {
  const { formatMessage } = useIntl();

  const initialValues = {
    reason: '',
  };

  const handleSubmit = async (values: { reason: string }, { resetForm }: any) => {
    await onSubmit(values);
    resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage({ id: 'ReportModal.title', defaultMessage: 'Report Content' })}
      modalClassName={styles.reportModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={styles.form}>
            <p className={styles.description}>
              <FormattedMessage
                id="ReportModal.description"
                defaultMessage="Please provide details about why you're reporting this content. This will help our moderators review it properly."
              />
            </p>

            <TextAreaField
              name="reason"
              label={formatMessage({
                id: 'ReportModal.reasonLabel',
                defaultMessage: 'Reason for reporting',
              })}
              placeholder={formatMessage({
                id: 'ReportModal.reasonPlaceholder',
                defaultMessage: 'Please describe why you think this content should be reviewed...',
              })}
              rows={5}
            />

            <div className={styles.actions}>
              <Button type="submit" disabled={isSubmitting} className={styles.submitButton}>
                <FormattedMessage id="ReportModal.submit" defaultMessage="Submit Report" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
