import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  foundationSectionTitle: {
    id: 'Footer.foundationSectionTitle',
    defaultMessage: 'Foundation',
  },
  foundationAboutUsLink: {
    id: 'Footer.foundationAboutUsLink',
    defaultMessage: 'About us',
  },
  foundationPrivacyPolicyLink: {
    id: 'Footer.foundationPrivacyPolicyLink',
    defaultMessage: 'Privacy Policy',
  },
  foundationTermsLink: {
    id: 'Footer.foundationTermsLink',
    defaultMessage: 'Terms of Service',
  },
  contactSectionTitle: {
    id: 'Footer.contactSectionTitle',
    defaultMessage: 'Find us',
  },
  contactFoundationName: {
    id: 'Footer.contactFoundationName',
    defaultMessage: 'Polonium Foundation',
  },
  contactAddress: {
    id: 'Footer.contactAddress',
    defaultMessage: 'Pl. Bankowy 2, 00-095 Warszawa/Poland',
  },
  contactNip: {
    id: 'Footer.contactNip',
    defaultMessage: 'NIP: 7010584408',
  },
  grantsHeading: {
    id: 'Footer.grantsHeading',
    defaultMessage: 'Grants Financing Page',
  },
  grantsDescription: {
    id: 'Footer.grantsDescription',
    defaultMessage:
      'Public task co-financed by the Ministry of Education and Science in 2023 within the program “Organizing and animating activities for the academic community.”',
  },
  forumEventsHeading: {
    id: 'Footer.forumEventsHeading',
    defaultMessage: 'Forum and Events Financing Pages',
  },
  forumEventsDescription: {
    id: 'Footer.forumEventsDescription',
    defaultMessage:
      'Project funded by the Ministry of Foreign Affairs of the Republic of Poland in the “POLONIA AND POLES ABROAD 2024 – REGRANTING” competition.',
  },

  disclaimerText: {
    id: 'Footer.disclaimerText',
    defaultMessage:
      'The project is co-funded within the program of the Ministry of Science and Higher Education under the name “DIALOG” in the project “2018-2020 Gateway to the Polish research diaspora: portal and events integrating the Polish international research community.”',
  },
  copyrights: {
    id: 'Footer.copyrights',
    defaultMessage: 'Copyright © {yearsRange}',
  },
});
