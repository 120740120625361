export const TermsOfServiceStatements = [
  {
    title: 'Accounts and membership',
    content:
      'If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and use our Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.',
  },
  {
    title: 'Billing and payments',
    content:
      'You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. If, in our judgement, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products at any time.',
  },
  {
    title: 'Accuracy of information',
    content:
      'Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to pricing, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or on any related Service is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or on any related Service has been modified or updated.',
  },
  {
    title: 'Third-party services',
    content:
      'If you decide to enable, access or use third-party services, be advised that your access and use of such other services are governed solely by the terms and conditions of such other services, and we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other services, including, without limitation, their content or the manner in which they handle data (including your data) or any interaction between you and the provider of such other services. You irrevocably waive any claim against Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa with respect to such other services. Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa is not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access or use of any such other services, or your reliance on the privacy practices, data security processes or other policies of such other services. You may be required to register for or log into such other services on their respective websites. By enabling any other services, you are expressly permitting Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa to disclose your data as necessary to facilitate the use or enablement of such other service.',
  },
  {
    title: 'Uptime guarantee',
    content:
      'We offer a Service uptime guarantee of 99% of available time per month. The service uptime guarantee does not apply to service interruptions caused by: <br />' +
      '<ol type="1">' +
      '<li>periodic scheduled maintenance or repairs we may undertake from time to time; </li>' +
      '<li>interruptions caused by you or your activities; </li>' +
      '<li>outages that do not affect core Service functionality; </li>' +
      '<li>causes beyond our control or that are not reasonably foreseeable; and </li>' +
      '<li>outages related to the reliability of certain programming environments. </li>' +
      '</ol>',
  },
  {
    title: 'Links to other websites',
    content:
      'Although this Website may link to other websites, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services, and content of any other third-parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website. Your linking to any other off-site websites is at your own risk.',
  },
  {
    title: 'Prohibited uses',
    content:
      'In addition to other terms as set forth in the Agreement, you are prohibited from using the Website or its Content: \n' +
      '<ol type="a">' +
      '<li>for any unlawful purpose; </li>' +
      '<li>to solicit others to perform or participate in any unlawful acts; </li>' +
      '<li>to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; </li>' +
      '<li>to infringe upon or violate our intellectual property rights or the intellectual property rights of others; </li>' +
      '<li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; </li>' +
      '<li>to submit false or misleading information; </li>' +
      '<li>to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; </li>' +
      '<li>to collect or track the personal information of others; </li>' +
      '<li>to spam, phish, pharm, pretext, spider, crawl, or scrape; </li>' +
      '<li>for any obscene or immoral purpose; or </li>' +
      '<li>to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses. </li>' +
      '</ol>',
  },
  {
    title: 'Polonium Network Forum ("the Forum")',
    content:
      'The Forum is a moderated platform designed to facilitate professional communication and collaboration among members of the Polish scientific diaspora, including students, researchers, and professionals. The Forum is intended to exchange scientific ideas, network, and promote events relevant to the community. <br /><br />' +
      '<strong>User Eligibility</strong><br />' +
      'To use the Forum, you must:<br />' +
      '<ol type="a">' +
      '<li>Be a registered user of the Polonium Network platform.</li>' +
      '<li>Agree to provide accurate and truthful information in your profile.</li>' +
      '</ol><br />' +
      '<strong>User Conduct</strong><br />' +
      'When using the Forum, you agree to:<br />' +
      '<ol type="a">' +
      '<li>Respect other users and their viewpoints.</li>' +
      '<li>Not post offensive, discriminatory, defamatory, or otherwise inappropriate content.</li>' +
      '<li>Not share or post spam, advertisements, or unrelated content.</li>' +
      '<li>Protect the confidentiality of any private information shared within the Forum.</li>' +
      '</ol><br />' +
      '<strong>Moderation</strong><br />' +
      'The Forum is moderated to ensure compliance with these Terms. Moderators may:<br />' +
      '<ol type="a">' +
      '<li>Remove content that violates these Terms.</li>' +
      '<li>Temporarily or permanently restrict access for users who breach these Terms.</li>' +
      '<li>Address disputes between users at their discretion.</li>' +
      '</ol><br />' +
      '<strong>Account Deletion and Content</strong><br />' +
      'If you choose to delete your account on the Polonium Network platform, all posts and content you have shared on the Forum will remain visible. However, instead of displaying your username, the content will be attributed to "Anonymous." <br /><br />' +
      '<strong>Liability Disclaimer</strong><br />' +
      'The Polonium Foundation is not liable for:<br />' +
      '<ol type="a">' +
      '<li>The accuracy or reliability of content posted by users.</li>' +
      '<li>Any damages resulting from interactions or disputes between users.</li>' +
      '</ol>',
  },
  {
    title: 'Intellectual property rights',
    content:
      'This Agreement does not transfer to you any intellectual property owned by Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa or third-parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa. All trademarks, service marks, graphics and logos used in connection with our Website or Services, are trademarks or registered trademarks of Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa or Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa licensors. Other trademarks, service marks, graphics and logos used in connection with our Website or Services may be the trademarks of other third-parties. Your use of our Website and Services grants you no right or license to reproduce or otherwise use any Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa or third-party trademarks.',
  },
  {
    title: 'Limitation of liability',
    content:
      'To the fullest extent permitted by applicable law, in no event will Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater of one US dollar, Euro, Polish zloty (PLN) or any amounts actually paid in cash by you to Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.',
  },
  {
    title: 'Indemnification',
    content:
      "You agree to indemnify and hold Fundacja Polonium, Pl. Bankowy 2, 00-095 Warszawa and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website or Services or any willful misconduct on your part.",
  },
  {
    title: 'Severability',
    content:
      'All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.',
  },
  {
    title: 'Dispute resolution',
    content:
      'The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Poland without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of Poland. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Poland, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.',
  },
  {
    title: 'Assignment',
    content:
      'You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole discretion and without obligation; any such assignment or transfer shall be null and void. We are free to assign any of its rights or obligations hereunder, in whole or in part, to any third-party as part of the sale of all or substantially all of its assets or stock or as part of a merger.',
  },
  {
    title: 'Changes and amendments',
    content:
      'You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Website and its Services.',
  },
  {
    title: 'Contacting us',
    content:
      'If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to <a href="mailto:iod@poloniumfoundation.org">iod@poloniumfoundation.org</a>',
  },
];
