import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'ForumPostPage.pageTitle',
    defaultMessage: 'Forum',
  },
  pageName: {
    id: 'ForumPostPage.pageName',
    defaultMessage: 'Forum',
    description: 'Forum page name',
  },
  forumTitle: {
    id: 'ForumPostPage.title',
    defaultMessage: 'Forum',
    description: 'Forum page title',
  },
  headingSubtitle: {
    id: 'ForumPostPage.headingSubtitle',
    defaultMessage: 'Connect, Collaborate, and Exchange Ideas',
    description: 'Forum page heading subtitle',
  },
  headingDescription: {
    id: 'ForumPostPage.headingDescription',
    defaultMessage:
      'Join vibrant discussions with researchers from around the world. Our forum is a space to share insights, seek advice, and spark collaborations. Whether you’re exploring new methods, seeking answers to challenges, or simply looking to connect with like-minded individuals, this is the place for you. Be part of the conversation and strengthen the global research community.',
    description: 'Forum page heading description',
  },
  errorLoadingPost: {
    id: 'ForumPostPage.errorLoadingPost',
    defaultMessage: 'Error loading post: {error}',
  },
  postNotFound: {
    id: 'ForumPostPage.postNotFound',
    defaultMessage: 'Post not found.',
  },
});
