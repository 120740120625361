import React from 'react';
import { ForumPost } from 'types/forum';
import { PostCard } from '../PostCard/PostCard';

interface PostListProps {
  posts: ForumPost[];
  handleVote?: (postId: string, voteType: 'upvote' | 'downvote') => Promise<void>;
}

export function PostList({ posts, handleVote }: PostListProps) {
  return (
    <div>
      {posts.map(post => (
        <PostCard key={post.id} post={post} handleVote={handleVote} />
      ))}
    </div>
  );
}
