import React from 'react'
import { ReactComponent as UpvoteIcon } from 'assets/icons/arrow-up.svg'
import { ReactComponent as DownvoteIcon } from 'assets/icons/arrow-down.svg'
import styles from './VoteGroup.module.scss'

interface RelationReactions {
  upvote: number
  downvote: number
}

interface VoteGroupProps {
  id: string
  relationReactions: RelationReactions
  handleVote?: (postId: string, type: 'upvote' | 'downvote') => void
}

export function VoteGroup({ id, relationReactions, handleVote }: VoteGroupProps) {
  return (
    <div className={styles.voteGroup}>
      <button
        className={styles.voteButton}
        aria-label="Upvote"
        onClick={() => handleVote?.(id, 'upvote')}
      >
        <UpvoteIcon className={`${styles.icon} ${styles.up}`} />
      </button>
      <span className={`${styles.voteCount} ${styles.up} ${styles.linkColor}`}>
        {relationReactions.upvote}
      </span>
      <span className={`${styles.voteCount} ${styles.down} ${styles.linkColor}`}>
        {relationReactions.downvote}
      </span>
      <button
        className={styles.voteButton}
        aria-label="Downvote"
        onClick={() => handleVote?.(id, 'downvote')}
      >
        <DownvoteIcon className={`${styles.icon} ${styles.down}`} />
      </button>
    </div>
  )
}
