import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  categoryTitle: {
    id: 'CategoriesSidebar.categoryTitle',
    defaultMessage: 'Category',
    description: 'Category title',
  },
  categoryDescription: {
    id: 'CategoriesSidebar.categoryDescription',
    defaultMessage: 'Description',
    description: 'Category description',
  },
  comprehensiveListMessage: {
    id: 'comprehensiveListMessage.text',
    defaultMessage:
      'We have done our best to make this list of categories as complete as possible. If you would like to suggest a new category that is not currently listed, please contact us at',
    description: 'Message for comprehensive category list and contact invitation on forum',
  },
});
