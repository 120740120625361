import React from 'react';
import Select from 'react-select/async-creatable';
import { useField } from 'formik';
import debounce from 'lodash/debounce';
import styles from './OrganizerSelect.module.scss';
import { Organizer } from '../../../../../../../types';

interface OrganizerSelectProps {
  name: string;
  label: string;
  placeholder: string;
  loadOptions: (query: string) => Promise<Organizer[]>;
}

export const OrganizerSelect = ({
  name,
  label,
  placeholder,
  loadOptions,
}: OrganizerSelectProps) => {
  const [field, meta, helpers] = useField(name);

  const transformInstitution = (institution: Organizer) => ({
    value: institution.attributes.id,
    label: institution.attributes.name,
  });

  const loadInstitutions = debounce(async (inputValue: string) => {
    if (inputValue.length < 3) return [];

    const institutions = await loadOptions(inputValue);
    return institutions?.map(transformInstitution);
  }, 300);

  const handleCreate = (inputValue: string) => {
    helpers.setValue({ id: null, name: inputValue, isNew: true });
  };

  const handleChange = (option: any) => {
    if (option) {
      helpers.setValue({ id: option.value, name: option.label });
    } else {
      helpers.setValue(null);
    }
  };

  return (
    <div className={styles.field}>
      <label className={styles.label}>{label}</label>
      <Select
        isClearable
        value={field.value ? { value: field.value.id, label: field.value.name } : null}
        onChange={handleChange}
        onCreateOption={handleCreate}
        loadOptions={loadInstitutions}
        placeholder={placeholder}
        formatCreateLabel={(inputValue: string) => `Add new institution "${inputValue}"`}
        classNamePrefix="select"
      />
      {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};
