import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApi } from 'api/ApiContext';
import { Loading } from 'components/Loading/Loading';
import ResponsivePagination from 'react-responsive-pagination';
import { Event, EventsResponse } from 'types/event';
import { Button } from 'components/Button/Button';
import EventCard from './components/EventCard/EventCard';
import '../../../grants/pages/GrantsList/pagination.css';
import styles from './EventsListPage.module.scss';
import { EventsFilterContainer } from './components/EventsFilterContainer/EventsFilterContainer';
import { messages } from './messages';

export type FilterOption = {
  label: string;
  value: string;
  disabled: boolean;
};

export const EventsListPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const api = useApi();

  const [eventsList, setEventsList] = useState<Event[]>([]);
  const [isFetchingEvents, setIsFetchingEvents] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(0);

  const [institutionsOptions, setInstitutionsOptions] = useState<FilterOption[]>();
  const [countryOptions, setCountryOptions] = useState<FilterOption[]>();
  const [eventFormatsOptions, setEventFormatsOptions] = useState<FilterOption[]>();

  const limit = 12;

  const getEvents = useCallback(
    async ({ query }: { query: string }) => {
      setIsFetchingEvents(true);
      try {
        window.history.replaceState(null, '', `/events${query}`);
        const response = await api.events.getEvents(query);
        const responseData = response.data as EventsResponse;

        if (!responseData.data) return;

        const found = responseData.meta?.collection?.found ?? 0;
        setTotalResults(found);
        setTotalPages(Math.ceil(found / limit));
        setEventsList(responseData.data);

        setInstitutionsOptions(
          responseData.meta?.filters?.available?.institutions?.map(item => ({
            label: item.name,
            value: String(item.id),
            disabled: false,
          }))
        );

        setCountryOptions(
          responseData.meta?.filters?.available?.countries?.map(item => ({
            label: item.name,
            value: item.id,
            disabled: false,
          }))
        );

        setEventFormatsOptions(
          responseData.meta?.filters?.available?.eventFormats?.map(item => ({
            label: item,
            value: item,
            disabled: false,
          }))
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetchingEvents(false);
      }
    },
    [api.events, limit]
  );

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('page', currentPage.toString());
    params.append('items', limit.toString());
    const finalUrl = `?${params.toString()}`;
    void getEvents({ query: finalUrl });
  }, [currentPage, getEvents]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    params.set('page', page.toString());
    params.set('items', limit.toString());
    const finalUrl = `?${params.toString()}`;
    void getEvents({ query: finalUrl });
  }

  return (
    <section className={styles.wrapper}>
      <Helmet title={formatMessage(messages.pageName)} />
      <section className={styles.headingContainer}>
        <div className={styles.headingContentWrapper}>
          <div>
            <h1 className={styles.headingTitle}>
              <span>
                <FormattedMessage {...messages.pageTitle} />
              </span>
            </h1>
            <h5 className={styles.headingSubtitle}>
              <FormattedMessage {...messages.headingSubtitle} />
            </h5>
          </div>

          <p className={styles.headingDescription}>
            <FormattedMessage {...messages.headingDescription} />
          </p>
        </div>
      </section>
      <section className={styles.events}>
        <div className={styles.eventsWithFiltersContainer}>
          <EventsFilterContainer
            isFetchingEvents={isFetchingEvents}
            getEvents={getEvents}
            setCurrentPage={setCurrentPage}
            institutionsOptions={institutionsOptions}
            countryOptions={countryOptions}
            eventFormatsOptions={eventFormatsOptions}
          />
          <div className={styles.addEventButtonWithFundingContainer}>
            <div className={styles.addEventButtonContainer}>
              <Button
                type="button"
                small
                className={styles.addEventButton}
                onClick={() => (window.location.href = '/events/new')}
              >
                <FormattedMessage {...messages.addEvent} />
              </Button>
            </div>
          </div>
        </div>
        <Loading isLoading={isFetchingEvents} className={styles.spinner}>
          <div className={styles.resultsContainer}>
            <div className={styles.results}>
              <FormattedMessage
                {...messages.availableEvents}
                values={{ eventsCount: totalResults }}
              />
            </div>
          </div>
          <div className={styles.eventsList}>
            {eventsList.map(event => (
              <EventCard key={event.id} event={event} />
            ))}
          </div>
          <div className={styles.eventsPagination}>
            {totalPages > 1 && (
              <ResponsivePagination
                total={totalPages}
                current={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </Loading>
      </section>
    </section>
  );
};
