// ForumPostPage.tsx
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loading } from 'components/Loading/Loading'
import { FormattedMessage, useIntl } from 'react-intl'
import { Helmet } from 'react-helmet-async'
import { useApi } from 'api/ApiContext'
import { ForumPost } from 'types/forum'
import { useForumPostData } from '../hooks/useForumPostData'
import { PostDetails } from '../components/PostDetails/PostDetails'
import { CategoriesSidebar } from '../components/CategoriesSidebar/CategoriesSidebar'
import { PostList } from '../components/PostList/PostList'
import styles from './ForumPostPage.module.scss'
import { messages } from './messages'

interface RouteParams {
  id: string
}

export function ForumPostPage() {
  const { id } = useParams<RouteParams>()
  const { formatMessage } = useIntl()
  const api = useApi()
  const { categories, post, comments, setComments, isLoading, error } = useForumPostData(id)
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null)
  const [categoryPosts, setCategoryPosts] = useState<ForumPost[]>([])
  const [isCategoryLoading, setIsCategoryLoading] = useState(false)
  const [hasCategoryError, setHasCategoryError] = useState<Error | null>(null)
  const [localPost, setLocalPost] = useState<ForumPost | null>(null)

  useEffect(() => {
    setLocalPost(post || null)
  }, [post])

  const handleVote = useCallback(
    async (postId: string, type: 'upvote' | 'downvote') => {
      try {
        api.forum.createReaction(postId, type).catch(console.error);
      } catch (error) {
        console.error('Error submitting vote:', error);
      }
    },
    [api.forum]
  );

  const fetchPostsForCategory = useCallback(
    async (categoryId: string | null) => {
      if (!categoryId) {
        setCategoryPosts([])
        setHasCategoryError(null)
        return
      }
      setIsCategoryLoading(true)
      setHasCategoryError(null)
      try {
        const response = await api.forum.getCategoryPosts(categoryId)
        setCategoryPosts(response.data.data)
      } catch (err) {
        setHasCategoryError(err as Error)
      } finally {
        setIsCategoryLoading(false)
      }
    },
    [api.forum]
  )

  const handleCategorySelect = (catId: string | null) => {
    if (selectedCategoryId === catId) {
      setSelectedCategoryId(null)
      setCategoryPosts([])
      setHasCategoryError(null)
      return
    }
    setSelectedCategoryId(catId)
  }

  useEffect(() => {
    fetchPostsForCategory(selectedCategoryId).catch(console.error)
  }, [selectedCategoryId, fetchPostsForCategory])

  if (error) {
    return (
      <div>
        <FormattedMessage {...messages.errorLoadingPost} values={{ error: error.message }} />
      </div>
    )
  }

  const handleCreateComment = async (content: string, parentId: string) => {
    if (!localPost) return
    try {
      const response = await api.forum.createPost({
        content,
        parentId: parentId || localPost.id,
      })
      const newComment = response.data.data
      setComments(prev => [...prev, newComment])
    } catch (err) {
      console.error('Error creating comment:', err)
    }
  }

  return (
    <div className={styles.wrapper}>
      <Helmet title={formatMessage(messages.forumTitle)} />
      <section className={styles.headingContainer}>
        <div className={styles.headingContentWrapper}>
          <div>
            <h1 className={styles.headingTitle}>
              <span>
                <FormattedMessage {...messages.pageTitle} />
              </span>
            </h1>
            <h5 className={styles.headingSubtitle}>
              <FormattedMessage {...messages.headingSubtitle} />
            </h5>
          </div>
          <p className={styles.headingDescription}>
            <FormattedMessage {...messages.headingDescription} />
          </p>
        </div>
      </section>
      <div className={styles.content}>
        <Loading isLoading={isLoading}>
          <div className={styles.mainLayout}>
            <CategoriesSidebar categories={categories} onCategorySelect={handleCategorySelect} />
            <main className={styles.mainContent}>
              {selectedCategoryId ? (
                <Loading isLoading={isCategoryLoading}>
                  {hasCategoryError ? (
                    <p>
                      <FormattedMessage
                        {...messages.errorLoadingPost}
                        values={{ error: hasCategoryError.message }}
                      />
                    </p>
                  ) : categoryPosts.length > 0 ? (
                    <PostList posts={categoryPosts} handleVote={handleVote} />
                  ) : (
                    !isCategoryLoading && <p>No posts found for this category.</p>
                  )}
                </Loading>
              ) : localPost ? (
                <PostDetails
                  post={localPost}
                  comments={comments}
                  onCommentCreate={handleCreateComment}
                  handleVote={handleVote}
                />
              ) : (
                <p>
                  <FormattedMessage {...messages.postNotFound} />
                </p>
              )}
            </main>
          </div>
        </Loading>
      </div>
    </div>
  )
}
