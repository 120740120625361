import { AxiosResponse } from 'axios';
import { ResourceClient } from './ResourceClient';
import {
  ForumCategory,
  ForumFeedResponse,
  ForumPost,
  ForumReaction,
  ForumReport,
} from 'types/forum';

export class ForumClient extends ResourceClient {
  async getHome(): Promise<AxiosResponse<ForumFeedResponse>> {
    return this.client.get('/forum');
  }

  async getHomeWithPagination(params: {
    page: number;
    per_page: number;
  }): Promise<
    AxiosResponse<{
      data: {
        attributes: { categories: { data: ForumCategory[] }; posts: { data: ForumPost[] } };
      };
    }>
  > {
    return this.client.get('/forum', { params });
  }

  getCategoryPosts(categoryId: string): Promise<AxiosResponse<{ data: ForumPost[] }>> {
    return this.client.get(`/forum/categories/${categoryId}/posts`);
  }

  async getCategoryPostsWithPagination(
    categoryId: string,
    params: {
      page: number;
      per_page: number;
    }
  ): Promise<AxiosResponse<{ data: ForumPost[] }>> {
    return this.client.get(`/forum/categories/${categoryId}/posts`, { params });
  }

  createPost(postData: {
    content: string;
    parentId?: string;
    categoryId?: string;
    postId?: string;
  }): Promise<AxiosResponse<{ data: ForumPost }>> {
    return this.client.post(`/forum/posts`, {
      post: postData,
    });
  }

  getPost(
    id: string
  ): Promise<AxiosResponse<{ post: { data: ForumPost }; comments: { data: ForumPost[] } }>> {
    return this.client.get(`/forum/posts/${id}`);
  }

  createReaction(
    postId: string,
    reactionType: 'upvote' | 'downvote'
  ): Promise<AxiosResponse<{ data: ForumReaction }>> {
    return this.client.post(`/forum/posts/${postId}/reactions`, {
      reaction: { reactionType },
    });
  }

  createReport(postId: string, reason: string): Promise<AxiosResponse<{ data: ForumReport }>> {
    return this.client.post(`/forum/posts/${postId}/reports`, {
      report: { reason },
    });
  }
}
