import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'intl/commonMessages';
import { ROUTES } from 'config/routes';
import { disableBodyScroll, ScrollPositions, useScrollPosition } from 'helpers';
import { useApi } from 'api/ApiContext';
import { useAuthContext } from 'modules/auth/AuthContext';
import { ButtonLink } from 'components/Button/ButtonLink';
import { ReactComponent as LogoMobile } from 'assets/icons/logo-mobile.svg';
import LogoDesktop from 'assets/icons/logo_dark.png';

import {
  LogoutButton,
  NavigationItem,
  NavigationLink,
  UserMenuButton,
  UserMenuDropdown,
} from './components';
import styles from './Navigation.module.scss';
import { useNavContext } from './NavContext';
import { MessagesCounter } from './components/NavigationItem/MessagesCounter';

export interface NavigationProps {
  isFloating: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ isFloating }) => {
  const { isMobileMenuOpen, toggleMobileMenuOpen, closeMobileMenu } = useNavContext();
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [isHidden, setHidden] = useState<boolean>(false);
  const [isSticky, setSticky] = useState<boolean>(false);
  const threshold = 100;
  const { isAuthorized, logOut } = useAuthContext();

  const hideOnScroll = useCallback(({ currentPosition, lastPosition }: ScrollPositions) => {
    setSticky(currentPosition > threshold);

    if (!currentPosition) {
      setHidden(false);
    }

    if (currentPosition > threshold) {
      setHidden(lastPosition < currentPosition);
    }
  }, []);

  useScrollPosition(hideOnScroll);

  const toggleDropdownOpen = useCallback(() => {
    setDropdownOpen(prevIsOpen => !prevIsOpen);
  }, []);

  const close = useCallback(() => {
    closeMobileMenu();
    setDropdownOpen(false);
  }, [closeMobileMenu]);

  useEffect(() => {
    disableBodyScroll(isMobileMenuOpen);

    return () => disableBodyScroll(false);
  }, [isMobileMenuOpen]);

  const { auth } = useApi();

  const logoutUser = useCallback(async () => {
    try {
      await auth.signOut();
    } finally {
      logOut();
      close();
    }
  }, [auth, logOut, close]);

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.sticky]: isSticky,
        [styles.hidden]: isHidden,
        [styles.floating]: isFloating,
      })}
    >
      <header
        className={classnames(styles.navbar, {
          [styles.sticky]: isSticky,
          [styles.floating]: isFloating,
        })}
      >
        <Link to={ROUTES.HOME} className={styles.logoLink}>
          <LogoMobile className={styles.logoMobile} />
          <img src={LogoDesktop} className={styles.logoDesktop} alt="Logo" />
        </Link>

        <button
          className={classnames(styles.hamburger, { [styles.open]: isMobileMenuOpen })}
          onClick={toggleMobileMenuOpen}
        >
          <span className={styles.bar} />
        </button>

        {isMobileMenuOpen && <div className={styles.overlay} onClick={close} />}

        {isDropdownOpen && <div className={styles.overlayTransparent} onClick={close} />}

        <nav className={classnames(styles.menu, { [styles.menuOpen]: isMobileMenuOpen })}>
          {isAuthorized ? (
            <ul className={styles.navList}>
              <NavigationLink to={ROUTES.COMMUNITY} onClick={close}>
                <FormattedMessage {...commonMessages.community} />
              </NavigationLink>

              <NavigationLink to={ROUTES.FORUM} onClick={close}>
                <FormattedMessage {...commonMessages.forum} />
              </NavigationLink>

              <NavigationLink to={ROUTES.EVENTS} onClick={close}>
                <FormattedMessage {...commonMessages.events} />

              </NavigationLink>

              <NavigationLink to={ROUTES.GRANTS} onClick={close}>
                <FormattedMessage {...commonMessages.grant} />
              </NavigationLink>

              <NavigationLink to={ROUTES.CAREER} onClick={close}>
                <FormattedMessage {...commonMessages.career} />
              </NavigationLink>

              <NavigationLink to={ROUTES.PARTNERSHIP} onClick={close}>
                <FormattedMessage {...commonMessages.becomePartner} />
              </NavigationLink>

              <MessagesCounter />
            </ul>
          ) : (
            <ul className={styles.navList}>
              <NavigationLink to={ROUTES.HOME} onClick={close}>
                <FormattedMessage {...commonMessages.homeButton} />
              </NavigationLink>

              <NavigationLink to={ROUTES.PARTNERSHIP} onClick={close}>
                <FormattedMessage {...commonMessages.becomePartner} />
              </NavigationLink>

              <NavigationLink to={ROUTES.ABOUT} onClick={close}>
                <FormattedMessage {...commonMessages.about} />
              </NavigationLink>
            </ul>
          )}

          <div className={styles.separator} />

          {isAuthorized ? (
            <>
              <ul className={styles.navListMobile}>
                <NavigationLink to={ROUTES.MY_PROFILE} onClick={close}>
                  <FormattedMessage {...commonMessages.profile} />
                </NavigationLink>

                <NavigationLink to={ROUTES.SETTINGS} onClick={close}>
                  <FormattedMessage {...commonMessages.settings} />
                </NavigationLink>

                <LogoutButton onClick={logoutUser} />
              </ul>

              <UserMenuButton onClick={toggleDropdownOpen} isOpen={isDropdownOpen} />
            </>
          ) : (
            <ul className={styles.navList}>
              <NavigationItem>
                <Link to={ROUTES.LOGIN} className={styles.login} onClick={close}>
                  <FormattedMessage {...commonMessages.login} />
                </Link>
              </NavigationItem>

              <NavigationItem>
                <ButtonLink to={ROUTES.SIGNUP} onClick={close} small={true}>
                  <FormattedMessage {...commonMessages.register} />
                </ButtonLink>
              </NavigationItem>
            </ul>
          )}
        </nav>

        <UserMenuDropdown isOpen={isDropdownOpen} onClick={close} onLogoutClick={logoutUser} />
      </header>
    </div>
  );
};
