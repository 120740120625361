import { useEffect, useState, useCallback } from 'react';
import { useApi } from 'api/ApiContext';
import { ForumCategory, ForumPost } from 'types/forum';

interface UseForumData {
  categories: ForumCategory[];
  posts: ForumPost[];
  isLoading: boolean;
  error: Error | null;
}

export function useForumData(): UseForumData {
  const api = useApi();
  const [categories, setCategories] = useState<ForumCategory[]>([]);
  const [posts, setPosts] = useState<ForumPost[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchForumData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.forum.getHome();
      const data = response.data.data.attributes;
      setCategories(data.categories?.data || []);
      setPosts(data.posts?.data || []);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  }, [api.forum]);

  useEffect(() => {
    fetchForumData();
  }, [fetchForumData]);

  return { categories, posts, isLoading, error };
}
