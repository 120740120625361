import { defineMessages } from 'react-intl.macro';

export const newPostFormMessages = defineMessages({
  placeholder: {
    id: 'NewPostForm.placeholder',
    defaultMessage: 'Write a post...',
    description: 'Placeholder text for the post content textarea when collapsed',
  },
  contentLabel: {
    id: 'NewPostForm.contentLabel',
    defaultMessage: 'Post Content',
    description: 'Label for the post content textarea',
  },
  categoryLabel: {
    id: 'NewPostForm.categoryLabel',
    defaultMessage: 'Category',
    description: 'Label for the category dropdown',
  },
  categoryPlaceholder: {
    id: 'NewPostForm.categoryPlaceholder',
    defaultMessage: 'Select a category',
    description: 'Placeholder text for the category dropdown',
  },

  submit: {
    id: 'NewPostForm.submit',
    defaultMessage: 'Create Post',
    description: 'Text for the submit button',
  },
  clear: {
    id: 'NewPostForm.clear',
    defaultMessage: 'Clear',
    description: 'Text for the clear button in category dropdown',
  },

  contentRequired: {
    id: 'NewPostForm.contentRequired',
    defaultMessage: 'Please enter post content',
    description: 'Error message when content is required',
  },
  categoryRequired: {
    id: 'NewPostForm.categoryRequired',
    defaultMessage: 'Please select a category',
    description: 'Error message when category selection is required',
  },

  postCreated: {
    id: 'NewPostForm.postCreated',
    defaultMessage: 'Post created successfully',
    description: 'Success message shown after post creation',
  },
});
