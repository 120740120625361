import React from 'react';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'react-datepicker';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './CustomDatePickerDays.module.scss';
import { messages } from './messages';

interface CustomDatePickerDaysProps {
  selected: Date | null;
  onChange: (date: Date) => void;
  onClear: () => void;
  className?: string;
  labelText?: string;
  placeHolderText?: string;
  dateFormat?: string;
  error?: boolean;
  errorMessage?: string;
  minDate?: Date;
}

export const CustomDatePickerDays: React.FC<CustomDatePickerDaysProps> = ({
  selected,
  onChange,
  onClear,
  className,
  labelText,
  placeHolderText,
  dateFormat = 'yyyy-MM-dd',
  errorMessage,
  error = false,
  minDate,
}) => {
  const handleDateChange = (date: Date | null) => {
    if (!date) {
      onClear();
      return;
    }

    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    onChange(utcDate);
  };

  return (
    <div className={`${styles.container} ${className} ${error ? styles.hasError : ''}`}>
      <div className={styles.labelContainer}>
        <p className={styles.label}>{labelText}</p>
        <button type="button" className={styles.clearContainer} onClick={onClear}>
          <TrashIcon className={styles.trashIcon} />
          <p className={styles.clearText}>
            <FormattedMessage {...messages.datePickerClear} />
          </p>
        </button>
      </div>
      <DatePicker
        selected={selected}
        onChange={handleDateChange}
        dateFormat={dateFormat}
        placeholderText={placeHolderText}
        className={styles.datepicker}
        calendarClassName={styles.calendar}
        dayClassName={date =>
          date?.toISOString() === selected?.toISOString() ? styles.selectedDay : undefined
        }
        minDate={minDate}
      />
      {error && errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};
