import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  timeAgo: {
    id: 'PostDetails.timeAgo',
    defaultMessage: ' • created {time} ago',
  },
  commentsTitle: {
    id: 'PostDetails.commentsTitle',
    defaultMessage: 'Comments',
  },
  noComments: {
    id: 'PostDetails.noComments',
    defaultMessage: 'No comments yet.',
  },
  postedBy: {
    id: 'PostDetails.postedBy',
    defaultMessage: 'Posted by:',
  },
  at: {
    id: 'PostDetails.at',
    defaultMessage: 'at',
  },
  addCommentPlaceholder: {
    id: 'PostDetails.addCommentPlaceholder',
    defaultMessage: 'Add a comment...',
  },
  addCommentButton: {
    id: 'PostDetails.addCommentButton',
    defaultMessage: 'Add Comment',
  },
});
