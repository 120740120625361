import React from 'react';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'react-datepicker';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './CustomDatePicker.module.scss';
import { messages } from './messages';

interface CustomDatePickerProps {
  selected: Date | null;
  onChange: (date: Date) => void;
  onClear: () => void;
  className?: string;
  labelText?: string;
  placeHolderText?: string;
  dateFormat?: string;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  selected,
  onChange,
  onClear,
  className,
  labelText,
  placeHolderText,
  dateFormat = 'yyyy-MM',
}) => {
  const handleDateChange = (date: Date | null) => {
    if (!date) {
      onClear();
      return;
    }

    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
    onChange(utcDate);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.labelContainer}>
        <p className={styles.label}>{labelText}</p>

        <button type="button" className={styles.clearContainer} onClick={onClear}>
          <TrashIcon className={styles.trashIcon} />
          <p className={styles.clearText}>
            <FormattedMessage {...messages.datePickerClear} />
          </p>
        </button>
      </div>
      <DatePicker
        selected={selected}
        onChange={handleDateChange}
        dateFormat={dateFormat}
        showMonthYearPicker
        placeholderText={placeHolderText}
        className={styles.datepicker}
        onClear={() => onClear()}
      />
    </div>
  );
};
