import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'NewEventPage.pageTitle',
    defaultMessage: 'Add event',
  },
  pageName: {
    id: 'NewEventPage.pageName',
    defaultMessage: 'Add Event',
    description: 'Add event page name',
  },
  headingSubtitle: {
    id: 'NewEventPage.headingSubtitle',
    defaultMessage: 'Share Your Event with the Research Community',
    description: 'Add event page heading subtitle',
  },
  headingDescription: {
    id: 'NewEventPage.headingDescription',
    defaultMessage:
      'Promote your conference, workshop, or symposium to a global audience. Provide the details, and we’ll help you reach researchers who are eager to connect, learn, and collaborate. Let’s make your event a success together.',
    description: 'Add event page heading description',
  },
  addPhoto: {
    id: 'NewEventPage.addPhoto',
    defaultMessage: '+ ADD PHOTO',
    description: 'Add photo button label',
  },
  eventSubmissionSuccess: {
    id: 'NewEventPage.eventSubmissionSuccess',
    defaultMessage: `Your event request has been submitted successfully. Once reviewed by administrators,
    you will be notified via email. Typically, this process takes up to 48 hours.
    Thank you for your contribution to fostering meaningful scientific connections.`,
    description: 'Message displayed after a successful event submission',
  },
});
