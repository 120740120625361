import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  formTitle: {
    id: 'NewEventForm.formTitle',
    defaultMessage: 'Add event',
    description: 'Form title for adding new event',
  },
  nameLabel: {
    id: 'NewEventForm.nameLabel',
    defaultMessage: 'Event Name',
  },
  namePlaceholder: {
    id: 'NewEventForm.namePlaceholder',
    defaultMessage: 'Enter event name',
  },
  organizerLabel: {
    id: 'NewEventForm.organizerLabel',
    defaultMessage: 'Organizer',
  },
  organizerPlaceholder: {
    id: 'NewEventForm.organizerPlaceholder',
    defaultMessage: 'Enter at least the first 5 characters to see matching organizers. If none are found, you can add a new one',
    description: 'Placeholder text for organizer search input',
  },
  startDateLabel: {
    id: 'NewEventForm.startDateLabel',
    defaultMessage: 'Start Date',
  },
  startDatePlaceholder: {
    id: 'NewEventForm.startDatePlaceholder',
    defaultMessage: 'Select start date',
  },
  endDateLabel: {
    id: 'NewEventForm.endDateLabel',
    defaultMessage: 'End Date',
  },
  endDatePlaceholder: {
    id: 'NewEventForm.endDatePlaceholder',
    defaultMessage: 'Select end date',
  },
  urlLabel: {
    id: 'NewEventForm.urlLabel',
    defaultMessage: 'Event URL',
  },
  urlPlaceholder: {
    id: 'NewEventForm.urlPlaceholder',
    defaultMessage: 'https://...',
  },
  countryLabel: {
    id: 'NewEventForm.countryLabel',
    defaultMessage: 'Country',
  },
  countryPlaceholder: {
    id: 'NewEventForm.countryPlaceholder',
    defaultMessage: 'Select country',
  },
  formatsLabel: {
    id: 'NewEventForm.formatsLabel',
    defaultMessage: 'Event Format',
  },
  formatsPlaceholder: {
    id: 'NewEventForm.formatsPlaceholder',
    defaultMessage: 'Select event format',
  },
  descriptionLabel: {
    id: 'NewEventForm.descriptionLabel',
    defaultMessage: 'Description',
  },
  descriptionPlaceholder: {
    id: 'NewEventForm.descriptionPlaceholder',
    defaultMessage: 'Enter event description (max 250 characters)',
  },
  reset: {
    id: 'NewEventForm.reset',
    defaultMessage: 'Reset',
  },
  save: {
    id: 'NewEventForm.save',
    defaultMessage: 'Save',
  },
  eventSubmissionSuccess: {
    id: 'NewEventForm.eventSubmissionSuccess',
    defaultMessage: `Your event request has been created successfully. Once reviewed by administrators,
    you will be notified via email. Typically, this process takes up to 48 hours.
    Thank you for your contribution to fostering meaningful scientific connections.`,
    description: 'Success message after event submission',
  },
});
