import { AxiosResponse } from 'axios';
import { CountriesResponse, CreateEventSuccessResponse, EventsResponse, OrganizerResponse } from 'types/event';

import { ResourceClient } from './ResourceClient';

export type CreateEventData = {
  name: string;
  organizer: { id: string; name: string } | null;
  startDate: Date | null;
  endDate: Date | null;
  urlReference: string;
  country: string[];
  eventFormats: string[];
  description: string;
  image: File | null;
};

export class EventsClient extends ResourceClient {
  getEvents(query?: string): Promise<AxiosResponse<EventsResponse>> {
    const url = `/events${query || ''}`;
    return this.client.get(url);
  }

  getCountries(): Promise<AxiosResponse<CountriesResponse>> {
    return this.client.get('/events/countries');
  }

  searchInstitutions(query: string): Promise<AxiosResponse<OrganizerResponse>> {
    return this.client.get(`/institutions?query=${query}`);
  }

  createEvent(eventData: CreateEventData): Promise<AxiosResponse<CreateEventSuccessResponse>>
  {
    const formData = new FormData();
    formData.append('event[name]', eventData.name);
    if (eventData.description) formData.append('event[description]', eventData.description);
    if (eventData.organizer?.id) {
      formData.append('event[institutionId]', eventData.organizer.id);
    } else {
      formData.append('event[institutionId]', eventData.organizer.name);
    }

    // formData.append('event[institutionId]', String(eventData.organizer?.id));
    formData.append('event[startAt]', eventData.startDate?.toISOString() || '');
    formData.append('event[endAt]', eventData.endDate?.toISOString() || '');
    formData.append('event[urlReference]', eventData.urlReference);
    if (eventData.country) {
      eventData.country.forEach(country => {
        formData.append('event[addressAttributes][]', country);
      });
    }
    if (eventData.eventFormats) {
      eventData.eventFormats.forEach(format => {
        formData.append('event[eventFormats][]', format);
      });
    }
    if (eventData.image) {
      formData.append('event[image]', eventData.image);
    }
    return this.client.post('/events', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
