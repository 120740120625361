import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  datePickerClear: {
    id: 'CustomDatePickerDays.clear',
    defaultMessage: 'Clear',
  },

  datePickerLabel: {
    id: 'CustomDatePickerDays.label',
    defaultMessage: 'Choose date',
  },

  datePickerSelected: {
    id: 'CustomDatePickerDays.selected',
    defaultMessage: 'Selected date is {date}',
  },

  datePickerPlaceholder: {
    id: 'CustomDatePickerDays.placeholder',
    defaultMessage: 'Select a date',
  },

  datePickerPrevMonth: {
    id: 'CustomDatePickerDays.prevMonth',
    defaultMessage: 'Previous month',
  },

  datePickerNextMonth: {
    id: 'CustomDatePickerDays.nextMonth',
    defaultMessage: 'Next month',
  },

  datePickerInvalid: {
    id: 'CustomDatePickerDays.invalid',
    defaultMessage: 'Please enter a valid date',
  },

  datePickerOpen: {
    id: 'CustomDatePickerDays.open',
    defaultMessage: 'Calendar view is open',
  },

  datePickerClose: {
    id: 'CustomDatePickerDays.close',
    defaultMessage: 'Calendar view is closed',
  },

  datePickerChangeMonth: {
    id: 'CustomDatePickerDays.changeMonth',
    defaultMessage: 'Change month',
  },

  datePickerChangeYear: {
    id: 'CustomDatePickerDays.changeYear',
    defaultMessage: 'Change year',
  },
});
