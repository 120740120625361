import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  timeAgo: {
    id: 'PostCard.timeAgo',
    defaultMessage: ' • created {time} ago',
  },
  share: {
    id: 'PostCard.share',
    defaultMessage: 'Share',
  },
  award: {
    id: 'PostCard.award',
    defaultMessage: 'Award',
  },
  report: {
    id: 'PostCard.report',
    defaultMessage: 'Report',
  },
});
