import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  comprehensiveListMessage: {
    id: 'ComprehensiveListMessage.text',
    defaultMessage:
      'We did all in our power to make this list as comprehensive as possible, but if you would like to add a grant from your institution that is not currently on the list, please contact us at',
    description: 'Message for comprehensive list and contact invitation',
  },
  results: {
    id: 'SearchContainer.results',
    defaultMessage: 'Results available',
    description: 'Results',
  },
  searchLabelClear: {
    id: 'SearchContainer.searchLabelClear',
    defaultMessage: 'Clear all',
    description: 'Clear button text for location input',
  },
  submitButton: {
    id: 'SearchContainer.submitButton',
    defaultMessage: 'Filter',
    description: 'Filter',
  },
  cancelButton: {
    id: 'SearchContainer.cancelButton',
    defaultMessage: 'CANCEL',
    description: 'Cancel',
  },
});
