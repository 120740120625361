import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ForumCategory } from 'types/forum';
import classnames from 'classnames';

import { DropdownSelectMenu } from '../../../../grants/components/DropdownSelectMenu/DropdownSelectMenu';

import { newPostFormMessages } from './messages';
import styles from './NewPostForm.module.scss';

interface NewPostFormProps {
  categories: ForumCategory[];
  selectedCategoryId: string | null;
  onCreatePost: (postData: { content: string; categoryId: string }) => void;
}

const validationSchema = Yup.object().shape({
  content: Yup.string().required('Content is required'),
  categoryId: Yup.string().required('Category is required'),
});

export function NewPostForm({ categories, selectedCategoryId, onCreatePost }: NewPostFormProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [category, setCategory] = useState<string | null>(null);
  const intl = useIntl();

  const categoryOptions = categories.map(cat => ({
    label: cat.attributes.name,
    value: cat.id,
    disabled: false,
  }));

  const initialValues = {
    content: '',
    categoryId: selectedCategoryId || '',
  };

  const handleSubmit = (values: typeof initialValues, { resetForm }: any) => {
    onCreatePost({
      content: values.content,
      categoryId: values.categoryId,
    });
    resetForm();
    setIsExpanded(false);
  };

  useEffect(() => {
    setIsExpanded(false);
    setCategory(selectedCategoryId);
  }, [selectedCategoryId]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, isValid, dirty }) => (
        <Form className={styles.formWrapper}>
          <textarea
            name="content"
            value={values.content}
            onChange={e => setFieldValue('content', e.target.value)}
            onFocus={() => setIsExpanded(true)}
            placeholder={!isExpanded ? intl.formatMessage(newPostFormMessages.placeholder) : ''}
            className={classnames(isExpanded ? styles.textareaExpanded : styles.textareaCollapsed)}
          />

          {isExpanded && (
            <div className={styles.bottomRow}>
              <div className={styles.selectWrapper}>
                <DropdownSelectMenu
                  id="category-select"
                  name="categoryId"
                  options={categoryOptions}
                  label={intl.formatMessage(newPostFormMessages.categoryLabel)}
                  placeholder={intl.formatMessage(newPostFormMessages.categoryPlaceholder)}
                  query={values.categoryId ? [values.categoryId] : [category]}
                  setQuery={categories =>
                    setFieldValue('categoryId', category || selectedCategoryId || categories[0])
                  }
                  selectedItems={values.categoryId ? 1 : 0}
                  setSelectedItems={() => null}
                  setFieldValue={setFieldValue}
                  submit={async () => null}
                  wrapperClass={styles.selectWrapper}
                  single={true}
                  hideClearButton={true}
                />
              </div>

              <button type="submit" className={styles.submitButton} disabled={!isValid || !dirty}>
                {intl.formatMessage(newPostFormMessages.submit)}
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}
