import React from 'react'
import { ForumPost } from 'types/forum'
import { VoteGroup } from './elements/VoteGroup/VoteGroup'
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg'
import defaultAvatar from 'assets/icons/default-avatar.svg'
import styles from './PostDetails.module.scss'
import { ReplyForm } from './elements/ReplyForm/ReplyForm'
import { Comment } from './elements/Comment/Comment'
import { Button } from 'components/Button/Button'
import { FormattedMessage } from 'react-intl'
import { messages } from './messages';

interface PostDetailsProps {
  post: ForumPost
  comments: ForumPost[]
  onCommentCreate?: (content: string, parentId?: string) => void
  handleVote: (postId: string, type: 'upvote' | 'downvote') => void
}

export function PostDetails({ post, comments, onCommentCreate, handleVote }: PostDetailsProps) {
  const [isAddingComment, setIsAddingComment] = React.useState(false)

  return (
    <div className={styles.postDetails}>
      <div className={styles.postContent}>
        <div className={styles.postHeader}>
          <div className={styles.categoryLabel}>
            c/{post.attributes.category || 'unknown_category'}
          </div>
          <div className={styles.authorInfo}>
            <img
              src={post.relationships?.user?.data?.attributes?.avatarUrl || defaultAvatar}
              alt="User avatar"
              className={styles.avatar}
            />
            <div className={styles.authorMetadata}>
              <span className={styles.authorName}>{post.attributes.postedBy || 'anonymous'}</span>
              <span className={styles.timeAgo}>
                <FormattedMessage
                  {...messages.timeAgo}
                  values={{ time: post.attributes.timeAgo }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className={styles.content}>{post.attributes.content}</div>
        <div className={styles.postActions}>
          <VoteGroup
            id={post.id}
            relationReactions={{
              upvote: post.attributes.relationReactions?.upvote || 0,
              downvote: post.attributes.relationReactions?.downvote || 0
            }}
            handleVote={handleVote}
          />
          <button className={styles.actionButton}>
            <CommentIcon className={styles.icon} />
            {post.attributes.repliesCount}
          </button>
        </div>
      </div>
      <div className={styles.commentsSection}>
        <div className={styles.addComment}>
          {!isAddingComment ? (
            <Button secondary onClick={() => setIsAddingComment(true)}>
              + Add a comment
            </Button>
          ) : (
            <ReplyForm
              onSubmit={content => {
                onCommentCreate?.(content)
                setIsAddingComment(false)
              }}
              onCancel={() => setIsAddingComment(false)}
              replyingTo={post.attributes.postedBy || 'anonymous'}
            />
          )}
        </div>
        <div className={styles.commentsList}>
          {comments.map(comment => (
            <Comment
              key={comment.id}
              comment={comment}
              comments={comments}
              onCommentCreate={onCommentCreate || (() => null)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
