import React, { useState } from 'react';

import styles from './ReplyForm.module.scss';

interface ReplyFormProps {
  onSubmit: (content: string) => void;
  onCancel: () => void;
  replyingTo: string;
}

export function ReplyForm({ onSubmit, onCancel, replyingTo }: ReplyFormProps) {
  const [content, setContent] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!content.trim()) return;
    onSubmit(content.trim());
    setContent('');
  };

  return (
    <form className={styles.replyForm} onSubmit={handleSubmit}>
      <div className={styles.replyInput}>
        <span className={styles.replyingTo}>@{replyingTo}</span>
        <textarea
          value={content}
          onChange={e => setContent(e.target.value)}
          placeholder="What are your thoughts?"
          className={styles.commentTextarea}
        />
      </div>
      <div className={styles.replyFormActions}>
        <button type="button" onClick={onCancel} className={styles.cancelButton}>
          Cancel
        </button>
        <button type="submit" className={styles.submitButton}>
          Comment
        </button>
      </div>
    </form>
  );
}
