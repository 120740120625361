import React, { useState } from 'react';
import { Event } from 'types/event';
import EventImagePlaceholder1 from 'assets/images/event_placeholder/polonium_network_event_placeholder_1.png';
import EventImagePlaceholder2 from 'assets/images/event_placeholder/polonium_network_event_placeholder_2.png';
import EventImagePlaceholder3 from 'assets/images/event_placeholder/polonium_network_event_placeholder_3.png';
import EventImagePlaceholder4 from 'assets/images/event_placeholder/polonium_network_event_placeholder_4.png';
import EventImagePlaceholder5 from 'assets/images/event_placeholder/polonium_network_event_placeholder_5.png';
import EventImagePlaceholder6 from 'assets/images/event_placeholder/polonium_network_event_placeholder_6.png';
import EventImagePlaceholder7 from 'assets/images/event_placeholder/polonium_network_event_placeholder_7.png';
import EventImagePlaceholder8 from 'assets/images/event_placeholder/polonium_network_event_placeholder_8.png';
import EventImagePlaceholder9 from 'assets/images/event_placeholder/polonium_network_event_placeholder_9.png';
import EventImagePlaceholder10 from 'assets/images/event_placeholder/polonium_network_event_placeholder_10.png';
import EventImagePlaceholder11 from 'assets/images/event_placeholder/polonium_network_event_placeholder_11.png';
import EventImagePlaceholder12 from 'assets/images/event_placeholder/polonium_network_event_placeholder_12.png';
import EventImagePlaceholder13 from 'assets/images/event_placeholder/polonium_network_event_placeholder_13.png';
import EventImagePlaceholder14 from 'assets/images/event_placeholder/polonium_network_event_placeholder_14.png';
import EventImagePlaceholder15 from 'assets/images/event_placeholder/polonium_network_event_placeholder_15.png';
import EventImagePlaceholder16 from 'assets/images/event_placeholder/polonium_network_event_placeholder_16.png';
import { FormattedMessage } from 'react-intl';
import styles from './EventCard.module.scss';
import { messages } from './messages';
import { getRandomFallbackImageUrl } from '../../../../../../components/FallbackImage/FallbackImage';

const IMAGES_PLACEHOLDER = [
  EventImagePlaceholder1,
  EventImagePlaceholder2,
  EventImagePlaceholder3,
  EventImagePlaceholder4,
  EventImagePlaceholder5,
  EventImagePlaceholder6,
  EventImagePlaceholder7,
  EventImagePlaceholder8,
  EventImagePlaceholder9,
  EventImagePlaceholder10,
  EventImagePlaceholder11,
  EventImagePlaceholder12,
  EventImagePlaceholder13,
  EventImagePlaceholder14,
  EventImagePlaceholder15,
  EventImagePlaceholder16,
];

interface EventCardProps {
  event: Event;
}

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const evt = event.attributes;

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = getRandomFallbackImageUrl();
  };

  const getDateDisplay = (): string =>
    evt.startAt && evt.endAt
      ? `${evt.startAt} <> ${evt.endAt}`
      : evt.startAt
        ? `From: ${evt.startAt}`
        : evt.endAt
          ? `Until: ${evt.endAt}`
          : 'Check event details for dates.';

  const [randomPlaceholder] = useState(() => {
    const randomIndex = Math.floor(Math.random() * IMAGES_PLACEHOLDER.length);
    return IMAGES_PLACEHOLDER[randomIndex];
  });

  const imageSrc = evt.imageUrl ? evt.imageUrl : randomPlaceholder;

  return (
    <a
      href={evt.urlReference}
      target="_blank"
      rel="noopener noreferrer"
      className={`${styles.eventCard} ${evt.highlighted ? styles.highlighted : ''}`}
    >
      <div className={styles.imageContainer}>
        <img
          src={imageSrc}
          alt={evt.name}
          onError={handleImageError}
          className={styles.eventImage}
        />
        {evt.highlighted ? (
          <div className={styles.actionButtons}>
            <button className={styles.actionButton} aria-label="Favorite">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={styles.icon}
              >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            </button>
          </div>
        ) : null}
      </div>
      <div className={styles.eventContent}>
        <h2 className={styles.eventTitle}>{evt.name}</h2>

        <div className={styles.eventDetails}>
          <div className={styles.detailRow}>
            <span className={styles.label}>
              <FormattedMessage {...messages.dateLabel} />:
            </span>
            <span className={styles.value}>{getDateDisplay()}</span>
          </div>

          {evt.country && (
            <div className={styles.detailRow}>
              <span className={styles.label}>
                <FormattedMessage {...messages.countryLabel} />:
              </span>
              <span className={styles.value}>{evt.country}</span>
            </div>
          )}

          {evt.eventFormats && (
            <div className={styles.detailRow}>
              <span className={styles.label}>
                <FormattedMessage {...messages.eventFormatLabel} />:
              </span>
              <span className={styles.value}>{evt.eventFormats.join(', ')}</span>
            </div>
          )}


        {evt.organizer && (
          <div className={styles.detailRow}>
            <span className={styles.label}>
              <FormattedMessage {...messages.organizerLabel} />:
            </span>
            <span className={styles.value}>{evt.organizer}</span>
          </div>
        )}
        </div>

        {evt.description && <p className={styles.eventDescription}>{evt.description}</p>}
      </div>
    </a>
  );
};

 export default EventCard;
