import ImageDefaultFallback1 from 'assets/images/default_fallback/default_fallback_1.png';
import ImageDefaultFallback2 from 'assets/images/default_fallback/default_fallback_2.png';
import ImageDefaultFallback3 from 'assets/images/default_fallback/default_fallback_3.png';
import ImageDefaultFallback4 from 'assets/images/default_fallback/default_fallback_4.png';
import ImageDefaultFallback5 from 'assets/images/default_fallback/default_fallback_5.png';
import ImageDefaultFallback6 from 'assets/images/default_fallback/default_fallback_6.png';
import ImageDefaultFallback7 from 'assets/images/default_fallback/default_fallback_7.png';
import ImageDefaultFallback8 from 'assets/images/default_fallback/default_fallback_8.png';
import ImageDefaultFallback9 from 'assets/images/default_fallback/default_fallback_9.png';
import ImageDefaultFallback10 from 'assets/images/default_fallback/default_fallback_10.png';
import ImageDefaultFallback11 from 'assets/images/default_fallback/default_fallback_11.png';
import ImageDefaultFallback12 from 'assets/images/default_fallback/default_fallback_12.png';
import ImageDefaultFallback13 from 'assets/images/default_fallback/default_fallback_13.png';

export const DEFAULT_FALLBACK_LIST = [
  ImageDefaultFallback1,
  ImageDefaultFallback2,
  ImageDefaultFallback3,
  ImageDefaultFallback4,
  ImageDefaultFallback5,
  ImageDefaultFallback6,
  ImageDefaultFallback7,
  ImageDefaultFallback8,
  ImageDefaultFallback9,
  ImageDefaultFallback10,
  ImageDefaultFallback11,
  ImageDefaultFallback12,
  ImageDefaultFallback13,
];

export function getRandomFallbackImageUrl() {
  const idx = Math.floor(Math.random() * DEFAULT_FALLBACK_LIST.length);
  return DEFAULT_FALLBACK_LIST[idx];
}
