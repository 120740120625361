import React from 'react';
import classnames from 'classnames';
import { Field, FieldConfig, useField } from 'formik';
import styles from './TextAreaField.module.scss';
import { FieldError } from '../FieldError/FieldError';

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface TextAreaFieldProps extends Omit<TextAreaProps, keyof FieldConfig>, FieldConfig {
  label?: React.ReactChild;
  placeholder?: string;
  prompt?: React.ReactChild;
  isValid?: boolean;
  isInvalid?: boolean;
  rows?: number;
  classNames?: {
    wrapper?: string;
    textareaWrapper?: string;
    textarea?: string;
    textareaInvalid?: string;
    label?: string;
  };
}

export const TextAreaField: React.FC<TextAreaFieldProps> = ({
  name,
  label,
  placeholder,
  prompt,
  isValid,
  isInvalid,
  rows = 4,
  classNames = {},
  ...props
}) => {
  const [field, { error, touched }] = useField({ name });
  const hasError = !!error && touched;
  const hasNoError = touched && !error;

  const textareaClassNames = classnames(
    styles.textarea,
    classNames.textarea,
    {
      [styles.invalid]: isInvalid || hasError,
      [styles.valid]: isValid || hasNoError,
    },
    (isInvalid || hasError) && classNames.textareaInvalid
  );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      const textarea = e.target as HTMLTextAreaElement;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const value = textarea.value;
      const newValue = value.substring(0, start) + '\n' + value.substring(end);
      field.onChange({
        target: {
          name: field.name,
          value: newValue,
        },
      });

      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + 1;
      }, 0);
    }
  };

  return (
    <div className={classnames(styles.wrapper, classNames.wrapper)}>
      <label className={classnames(styles.label, classNames.label)} htmlFor={name}>
        {label}
      </label>
      {prompt && <p className={styles.prompt}>{prompt}</p>}
      <div className={classnames(styles.textareaWrapper, classNames.textareaWrapper)}>
        <Field
          as="textarea"
          className={textareaClassNames}
          id={name}
          name={name}
          placeholder={placeholder}
          rows={rows}
          onKeyDown={handleKeyDown}
          {...field}
          {...props}
        />
        {hasError && <FieldError name={name} />}
      </div>
    </div>
  );
};
