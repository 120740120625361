import React from 'react';
import { RouteProps, Switch } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { EventsListPage } from './pages/EventsListPage/EventsListPage';
import { NewEventPage } from './pages/NewEventPage/NewEventPage';

export const EventsRoutes: React.FC<RouteProps> = () => (
  <Switch>
    <ProtectedRoute exact path={ROUTES.EVENTS_NEW} component={NewEventPage} />
    <ProtectedRoute exact path={ROUTES.EVENTS} component={EventsListPage} />
  </Switch>
);
