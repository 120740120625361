import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  dateLabel: {
    id: 'EventsCard.dateLabel',
    defaultMessage: 'Date',
  },
  countryLabel: {
    id: 'EventsCard.countryLabel',
    defaultMessage: 'Country',
  },
  eventFormatLabel: {
    id: 'EventsCard.eventFormatLabel',
    defaultMessage: 'Event Format',
  },
  organizerLabel: {
    id: 'EventsCard.organizerLabel',
    defaultMessage: 'Organizer',
  },
});
