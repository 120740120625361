import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  institutionsTitle: {
    id: 'EventsListPage.institutionsTitle',
    defaultMessage: 'Institutions',
    description: 'Institutions section title',
  },
  filterByMonthLabel: {
    id: 'EventsListPage.filterByMonthLabel',
    defaultMessage: 'Filter by Month',
    description: 'Filter by month label',
  },
  highlightsTitle: {
    id: 'EventsListPage.highlightsTitle',
    defaultMessage: 'Highlights',
    description: 'Highlights section title',
  },
  countryTitle: {
    id: 'EventsListPage.countryTitle',
    defaultMessage: 'Countries',
    description: 'Country section title',
  },
  eventFormatsTitle: {
    id: 'EventsListPage.eventFormatsTitle',
    defaultMessage: 'Event Formats',
    description: 'Event format section title',
  },
  selectInstitutionsPlaceholder: {
    id: 'EventsListPage.selectInstitutionsPlaceholder',
    defaultMessage: 'Select Institutions',
    description: 'Select institutions dropdown placeholder',
  },
  selectDatePlaceholder: {
    id: 'EventsListPage.selectDatePlaceholder',
    defaultMessage: 'Select Date',
    description: 'Select date input placeholder',
  },
  selectCountriesPlaceholder: {
    id: 'EventsListPage.selectCountriesPlaceholder',
    defaultMessage: 'Select Countries',
    description: 'Select country dropdown placeholder',
  },
  selectEventFormatsPlaceholder: {
    id: 'EventsListPage.selectEventFormatsPlaceholder',
    defaultMessage: 'Select Event Formats',
    description: 'Select event format dropdown placeholder',
  },
  filterButtonText: {
    id: 'EventsListPage.filterButtonText',
    defaultMessage: 'FILTER',
    description: 'Filter button text',
  },
  clearButtonText: {
    id: 'EventsListPage.clearButtonText',
    defaultMessage: 'CLEAR',
    description: 'Clear button text',
  },
  clearAllButtonText: {
    id: 'EventsListPage.clearAllButtonText',
    defaultMessage: 'CLEAR ALL',
    description: 'Clear all button text',
  },
  cancelButtonText: {
    id: 'EventsListPage.cancelButtonText',
    defaultMessage: 'CANCEL',
    description: 'Cancel button text',
  },
});
