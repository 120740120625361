import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';
import { array, object, string } from 'yup';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter_list.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { DropdownSelectMenu } from '../../../../../grants/components/DropdownSelectMenu/DropdownSelectMenu';
import { CustomDatePicker } from '../../../components/CustomDatePicker/CustomDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './EventsFilterContainer.module.scss';
import { messages } from './messages';

interface EventsFilterValues {
  institutions: string[];
  filterByMonth: Date | null;
  countries: string[];
  eventFormats: string[];
}

export type FilterOption = {
  label: string;
  value: string;
  disabled: boolean;
};

interface EventsFilterContainerProps {
  isFetchingEvents: boolean;
  getEvents: ({ query }: { query: string }) => Promise<void>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  institutionsOptions?: FilterOption[];
  countryOptions?: FilterOption[];
  eventFormatsOptions?: FilterOption[];
}

const validationSchema = object().shape({
  institutions: array(string()),
  filterByMonth: string().nullable(),
  countries: array(string()),
  eventFormats: array(string()),
});

export const EventsFilterContainer: React.FC<EventsFilterContainerProps> = ({
                                                                              isFetchingEvents,
                                                                              getEvents,
                                                                              setCurrentPage,
                                                                              institutionsOptions,
                                                                              countryOptions,
                                                                              eventFormatsOptions,
                                                                            }) => {
  const [selectedOrganizatorItems, setSelectedOrganizatorItems] = useState(0);
  const [selectedCountryItems, setSelectedCountryItems] = useState(0);
  const [selectedEventFormatItems, setSelectedEventFormatItems] = useState(0);
  const [modalIsOpen, setIsModalOpen] = useState(false);

  const handleFilterSubmit = (values: EventsFilterValues) => {
    setCurrentPage(1);
    const params = new URLSearchParams();

    if (values.institutions && values.institutions.length > 0) {
      values.institutions.forEach((inst) => params.append('institutions[]', inst));
    }

    if (values.filterByMonth) {
      const utcDate = new Date(values.filterByMonth);
      const formattedMonth = `${utcDate.getUTCFullYear()}-${String(utcDate.getUTCMonth() + 1).padStart(2, '0')}`;
      params.append('filterByMonth', formattedMonth);
    }

    if (values.countries && values.countries.length > 0) {
      values.countries.forEach((loc) => params.append('countries[]', loc));
    }

    if (values.eventFormats && values.eventFormats.length > 0) {
      values.eventFormats.forEach((fmt) => params.append('eventFormats[]', fmt));
    }

    const finalUrl = `?page=1&items=16&${params.toString()}`;
    void getEvents({ query: finalUrl });
    window.history.pushState(null, '', '/events' + finalUrl);

    setIsModalOpen(false);
  };

  const handleFormReset = () => {
    setSelectedOrganizatorItems(0);
    setSelectedCountryItems(0);
    setSelectedEventFormatItems(0);

    void getEvents({ query: '?page=1&items=16' });
    window.history.pushState(null, '', '/events?page=1&items=16');
  };

  return (
    <div className={styles.searchContainer}>
      <Formik<EventsFilterValues>
        initialValues={{
          institutions: [],
          filterByMonth: null,
          countries: [],
          eventFormats: [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleFilterSubmit}
        onReset={handleFormReset}
      >
        {({ values, setFieldValue, submitForm }) => (
          <>
            <Form className={styles.form} noValidate>
              <DropdownSelectMenu
                id="institutions"
                name="institutions"
                label={messages.institutionsTitle.defaultMessage}
                query={values.institutions}
                setQuery={(q: string[]) => {
                  setFieldValue('institutions', q);
                  setSelectedOrganizatorItems(q.length);
                }}
                setFieldValue={setFieldValue}
                placeholder={messages.selectInstitutionsPlaceholder.defaultMessage}
                selectedItems={selectedOrganizatorItems}
                setSelectedItems={setSelectedOrganizatorItems}
                submit={submitForm}
                wrapperClass={styles.dropdownSelect}
                options={institutionsOptions ?? []}
              />

              <CustomDatePicker
                selected={values.filterByMonth}
                onChange={(date) => {
                  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
                  setFieldValue('filterByMonth', utcDate);
                }}
                onClear={() => {
                  setFieldValue('filterByMonth', null);
                  void submitForm();
                }}
                className={styles.searchSelect}
                labelText={messages.filterByMonthLabel.defaultMessage}
                placeHolderText={messages.selectDatePlaceholder.defaultMessage}
                dateFormat="yyyy-MM"
              />

              <DropdownSelectMenu
                id="countries"
                name="countries"
                label={messages.countryTitle.defaultMessage}
                query={values.countries}
                setQuery={(q: string[]) => {
                  setFieldValue('countries', q);
                  setSelectedCountryItems(q.length);
                }}
                setFieldValue={setFieldValue}
                placeholder={messages.selectCountriesPlaceholder.defaultMessage}
                selectedItems={selectedCountryItems}
                setSelectedItems={setSelectedCountryItems}
                submit={submitForm}
                wrapperClass={styles.dropdownSelect}
                options={countryOptions ?? []}
              />

              <DropdownSelectMenu
                id="event-formats"
                name="eventFormats"
                label={messages.eventFormatsTitle.defaultMessage}
                query={values.eventFormats}
                setQuery={(q: string[]) => {
                  setFieldValue('eventFormats', q);
                  setSelectedEventFormatItems(q.length);
                }}
                setFieldValue={setFieldValue}
                placeholder={messages.selectEventFormatsPlaceholder.defaultMessage}
                selectedItems={selectedEventFormatItems}
                setSelectedItems={setSelectedEventFormatItems}
                submit={submitForm}
                wrapperClass={styles.dropdownSelect}
                options={eventFormatsOptions ?? []}
              />

              <div className={styles.filterButtonContainer}>
                <button type="reset" className={styles.clearContainer}>
                  <TrashIcon className={styles.trashIcon} />
                  <p className={styles.clearText}>
                    <FormattedMessage {...messages.clearAllButtonText} />
                  </p>
                </button>

                <Button
                  type="submit"
                  small
                  className={styles.submit}
                  isProcessing={isFetchingEvents}
                  disabled={isFetchingEvents}
                >
                  <FormattedMessage {...messages.filterButtonText} />
                </Button>

                <button
                  type="button"
                  className={styles.filterIconMobile}
                  onClick={() => setIsModalOpen(true)}
                >
                  <FilterIcon className={styles.filterIcon} />
                </button>
              </div>

              <Modal
                onClose={() => setIsModalOpen(false)}
                modalClassName={styles.modal}
                isOpen={modalIsOpen}
              >
                <div className={styles.modalForm}>
                  <div className={styles.closeIconContainer}>
                    <CloseIcon className={styles.closeIcon} onClick={() => setIsModalOpen(false)} />
                  </div>
                  <div className={styles.dropdownsWithButtonsContainer}>
                    <div className={styles.dropdownsContainer}>
                      <DropdownSelectMenu
                        id="institutions-mobile"
                        name="institutions"
                        label={messages.institutionsTitle.defaultMessage}
                        query={values.institutions}
                        setQuery={(q: string[]) => {
                          setFieldValue('institutions', q);
                          setSelectedOrganizatorItems(q.length);
                        }}
                        setFieldValue={setFieldValue}
                        placeholder={messages.selectInstitutionsPlaceholder.defaultMessage}
                        selectedItems={selectedOrganizatorItems}
                        setSelectedItems={setSelectedOrganizatorItems}
                        submit={submitForm}
                        wrapperClass={styles.dropdownSelectMobile}
                        options={institutionsOptions ?? []}
                      />

                      <CustomDatePicker
                        selected={values.filterByMonth}
                        onChange={(date) => {
                          const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
                          setFieldValue('filterByMonth', utcDate);
                        }}
                        onClear={() => {
                          setFieldValue('filterByMonth', null);
                          void submitForm();
                        }}
                        className={styles.dropdownSelectMobile}
                        labelText={messages.filterByMonthLabel.defaultMessage}
                        placeHolderText={messages.selectDatePlaceholder.defaultMessage}
                        dateFormat="yyyy-MM"
                      />

                      <DropdownSelectMenu
                        id="countries-mobile"
                        name="countries"
                        label={messages.countryTitle.defaultMessage}
                        query={values.countries}
                        setQuery={(q: string[]) => {
                          setFieldValue('countries', q);
                          setSelectedCountryItems(q.length);
                        }}
                        setFieldValue={setFieldValue}
                        placeholder={messages.selectCountriesPlaceholder.defaultMessage}
                        selectedItems={selectedCountryItems}
                        setSelectedItems={setSelectedCountryItems}
                        submit={submitForm}
                        wrapperClass={styles.dropdownSelectMobile}
                        options={countryOptions ?? []}
                      />

                      <DropdownSelectMenu
                        id="event-formats-mobile"
                        name="eventFormats"
                        label={messages.eventFormatsTitle.defaultMessage}
                        query={values.eventFormats}
                        setQuery={(q: string[]) => {
                          setFieldValue('eventFormats', q);
                          setSelectedEventFormatItems(q.length);
                        }}
                        setFieldValue={setFieldValue}
                        placeholder={messages.selectEventFormatsPlaceholder.defaultMessage}
                        selectedItems={selectedEventFormatItems}
                        setSelectedItems={setSelectedEventFormatItems}
                        submit={submitForm}
                        wrapperClass={styles.dropdownSelectMobile}
                        options={eventFormatsOptions ?? []}
                      />
                    </div>
                    <div className={styles.buttonsMobileContainer}>
                      <Button
                        type="submit"
                        onClick={() => {
                          void submitForm();
                          setIsModalOpen(false);
                        }}
                        small
                        className={styles.mobileSubmit}
                        isProcessing={isFetchingEvents}
                        disabled={isFetchingEvents}
                      >
                        <FormattedMessage {...messages.filterButtonText} />
                      </Button>
                      <button
                        className={styles.cancelButton}
                        onClick={() => setIsModalOpen(false)}
                        type="button"
                      >
                        <FormattedMessage {...messages.cancelButtonText} />
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
