import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  pageTitle: {
    id: 'EventsListPage.pageTitle',
    defaultMessage: 'Events',
  },
  pageName: {
    id: 'EventsListPage.pageName',
    defaultMessage: 'Event',
    description: 'Events page name',
  },
  headingSubtitle: {
    id: 'EventsListPage.headingSubtitle',
    defaultMessage: 'Discover and Share Academic Opportunities',
    description: 'Events page heading subtitle',
  },
  headingDescription: {
    id: 'EventsListPage.headingDescription',
    defaultMessage:
      'Stay informed about upcoming conferences, workshops, and symposiums relevant to your field. Explore events by topic, location, or type, and find opportunities to present your work and expand your network. Organizing an event? Share it with the community and let us help you amplify its reach.',
    description: 'Events page heading description',
  },
  availableEvents: {
    id: 'EventsListPage.availableEvents',
    defaultMessage: 'Available events ({eventsCount})',
    description: 'Available events',
  },
  addEvent: {
    id: 'EventsListPage.addEvent',
    defaultMessage: '+ ADD EVENT',
    description: 'Add event button label',
  },
});
